import Q from 'q';
import _ from 'underscore';

import FlightFrequentTravelerSelector
    from 'yinzhilv-js-sdk/frontend/common-project/component/yinzhilv/flight-frequent-traveler-selector/1.0.0/index.vue';

export default {
    components: {
        FlightFrequentTravelerSelector,
    },
    data() {
        return {
            selected_entity_aFlightFrequentTravelerSelector: null,
            // selected_entity_aFlightFrequentTravelerSelector: {
            //     "allName": "张杰(null/null)",
            //     "birthday": "1996-12-25",
            //     "companyId": 127,
            //     "createTime": "2020-09-16 17:18:47",
            //     "email": "765634165@qq.com",
            //     "enName": "null/null",
            //     "firstName": "",
            //     "id": 4,
            //     "idCard": "431126199612256235",
            //     "idType": 1,
            //     "idTypeMsg": "身份证",
            //     "idValidity": "2025-05-10",
            //     "initial": "Z",
            //     "isLong": 0,
            //     "lastName": "",
            //     "name": "张杰",
            //     "nationalityCode": 1,
            //     "nationalityName": "",
            //     "phone": "17373144539",
            //     "phoneCode": "086",
            //     "remark": "1996",
            //     "sex": 1,
            //     "spell": "zhangjie",
            //     "type": 1,
            //     "updateTime": null,
            //     "userType": 1,
            //     "userTypeMsg": "成人"
            // },

            row_list: [
                {
                    ref_001: `aFlightFrequentTravelerSelector_001_001`,
                    v_model_001: null,
                    ref_002: `aFlightFrequentTravelerSelector_001_002`,
                    v_model_002: null,
                },
                {
                    ref_001: `aFlightFrequentTravelerSelector_002_001`,
                    v_model_001: null,
                    ref_002: `aFlightFrequentTravelerSelector_002_002`,
                    v_model_002: null,
                },
                {
                    ref_001: `aFlightFrequentTravelerSelector_003_001`,
                    v_model_001: null,
                    ref_002: `aFlightFrequentTravelerSelector_003_002`,
                    v_model_002: null,
                },
            ],
        };
    },
    methods: {
        __btn_get_value_click() {
            const __this = this;
            console.log(__this.selected_entity_aFlightFrequentTravelerSelector);
        },

        __btn_set_value_click() {
            const __this = this;
            //这样赋值是不可以的，因为监听的是对象！
            // __this.selected_entity_aFlightFrequentTravelerSelector.stationCode = `GZQ`;
            //这样赋值才可以！
            __this.selected_entity_aFlightFrequentTravelerSelector = {
                // "allName": "张杰(null/null)",
                // "birthday": "1996-12-25",
                // "companyId": 127,
                // "createTime": "2020-09-16 17:18:47",
                // "email": "765634165@qq.com",
                // "enName": "null/null",
                // "firstName": "",
                "id": 4,
                // "idCard": "431126199612256235",
                // "idType": 1,
                // "idTypeMsg": "身份证",
                // "idValidity": "2025-05-10",
                // "initial": "Z",
                // "isLong": 0,
                // "lastName": "",
                // "name": "张杰",
                // "nationalityCode": 1,
                // "nationalityName": "",
                // "phone": "17373144539",
                // "phoneCode": "086",
                // "remark": "1996",
                // "sex": 1,
                // "spell": "zhangjie",
                // "type": 1,
                // "updateTime": null,
                // "userType": 1,
                // "userTypeMsg": "成人"
            };
        },

        __btn_reset_value_click() {
            const __this = this;
            //这样赋值是不可以的，因为监听的是对象！
            // __this.selected_entity_aFlightFrequentTravelerSelector.stationCode = ``;
            //这样赋值才可以！
            __this.selected_entity_aFlightFrequentTravelerSelector = {
                'id': '',
            };
            // __this.selected_entity_aFlightFrequentTravelerSelector = null;
        },

        __btn_test_click() {
            const __this = this;
            console.log(__this.row_list);

            // 搞不懂为什么这里需要索引0的元素
            // const aFlightFrequentTravelerSelector = __this.$refs[`aFlightFrequentTravelerSelector_1`][0];
            // console.log(aFlightFrequentTravelerSelector.$data);
        },

        __btn_add_row_click() {
            const __this = this;
            const row_list = __this.row_list;
            const length = row_list.length;
            const i = length + 1;

            row_list.push({
                ref: `aFlightFrequentTravelerSelector_${i}`
            });
        },

        __FlightFrequentTravelerSelector_event_handler_entity_selected(pEntity, pIndexEntity) {
            const __this = this;
            console.log(pEntity);
            console.log(pIndexEntity);
        },
    },
    watch: {
        selected_entity_aFlightFrequentTravelerSelector(pNewValue, pOldValue) {
            const __this = this;
            // console.log(`pNewValue=`);
            // console.log(pNewValue);
            // console.log(`pOldValue=`);
            // console.log(pOldValue);
        },
    },
    mounted() {
    },
    activated() {
        // const __this = this;
    },
    deactivated() {
        // const __this = this;
    },
}
